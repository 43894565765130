<template>
    <CForm ref="form" class="needs-validation"   >
        
        <CSelect
            label="Stok Kanalı: "
            placeholder="Seçiniz.."
            horizontal
            :value.sync="data.inventoryFormPos"
            :options="stockChannels"
        />
        <CInput
            label="SKU Code: "
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.inventoryFormSKUCode"
        />
        <CInput
            label="Ürün Adedi: "
            horizontal
            type="number"
            autocomplete="text"
            v-model="data.inventoryFormAmount"
        />
        <CTextarea
            label="Açıklama"
            placeholder="Açıklama..."
            horizontal
            v-model="data.inventoryFormDescription"
            rows="9"
        />
   </CForm>
</template>

<script>
   export default{
       name: "InventoryForm",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create')
                   this.data = {}
           }
       },
       computed:{
            stockChannels: function() {
               let data = []
               this.$store.getters.activePos.map(r => data.push({value:r.id, label: r.name}))
               return data
            }
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
           }
       },
   }
</script>